import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import ViewModal from "./viewModal";
import FilterableColumnTable from "../../components/filterableColumnTable";
import { useAppSelector } from "../../store/store";

const Client: React.FC = () => {
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const { logoUrl } = useAppSelector((state: any) => state.formReducer);
  const billingEntityId =
    location.state?.billingEntityId || localStorage.getItem("billingEntityId") || "";

  useEffect(() => {
    if (billingEntityId) {
      localStorage.setItem("billingEntityId", billingEntityId);
    }
  }, [billingEntityId]);

  useEffect(() => {
    if (logoUrl && window) {
      window.postMessage({ type: "UPDATE_LOGO", logoUrl }, "*");
    }
  }, [logoUrl]);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "26px", fontWeight: "700" }}>Client List</Typography>
          <Button
            variant="outlined"
            sx={{
              background: "#eff6ff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: 1, sm: "8px 16px" },
              "& .MuiButton-startIcon": {
                margin: { xs: "0", sm: "0 0 0 8px" },
              },
            }}
            onClick={() => setShowAddClientModal(true)}
            id="add_new_client_btn"
            startIcon={<AddIcon />}
          >
            <Typography
              sx={{
                display: { xs: "none", sm: "inline" },
                fontSize: "0.875rem",
                fontWeight: "500",
              }}
            >
              Add New Client
            </Typography>
          </Button>
        </Box>
      </Box>

      <FilterableColumnTable
        setShowAddClientModal={setShowAddClientModal}
        showAddClientModal={showAddClientModal}
      />

      {showViewModal && <ViewModal isViewPopup={showViewModal} setIsView={setShowViewModal} />}
    </>
  );
};

export default Client;
