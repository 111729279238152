import { useEffect, useState } from "react";
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  useMediaQuery,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SidebarComponent from "./SidebarComponent";
import FooterComponent from "../footer";
import dotnetApiClient from "../../config/dotnetApiClient";
import { setLogoUrl } from "../../store/slices/flowFormSlice";
import { logErrorFunction } from "../../helper/service";
import { useToken } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Error } from "@mui/icons-material";

const LayoutComponent = ({ children }: { children: React.ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { token, setToken } = useToken();
  const dispatch = useAppDispatch();
  const { logoUrl } = useAppSelector((state: any) => state.formReducer);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const drawerWidth = 240; // Sidebar width
  useEffect(() => {
    fetchLogoForLawyer();
  }, []);

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate("/login");
  };
  const fetchLogoForLawyer = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }
    setLoading(true);
    try {
      const response: any = await dotnetApiClient().get("/Billing/logo", {
        responseType: "blob",
      });
      const data = response.data;
      const blob = new Blob([data], { type: data.type });
      const url = URL.createObjectURL(blob);

      dispatch(setLogoUrl(url));
    } catch (error: any) {
      logErrorFunction("Error fetching logo:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div style={{ position: "relative", minHeight: "calc(100vh - 64px)" }}>
      <div id="errorScreen" className="errorScreen">
        <div>
          <Typography variant="h6">
            <Error sx={{ color: "#d32f2f" }} /> Something went wrong
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="inherit" sx={{ fontSize: 16 }}>
            Please refresh the page and try again.
          </Typography>
          <small style={{ color: "#707070" }}>
            <b>Request ID: </b>
            <span></span>
          </small>
          <Button
            variant="contained"
            sx={{ mt: 2, width: "100%" }}
            size="small"
            onClick={() => window.location.reload()}
          >
            Refresh the page
          </Button>
        </div>
      </div>
      {/* Header - Only for Mobile */}
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#1f2937",
            zIndex: 1201, // Ensure it appears above the sidebar
            width: "100%",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Logo */}
            <div style={{ flex: 1 }}>
              {!loading && logoUrl && (
                <img src={logoUrl} alt="Logo" className=" h-[40px] max-w-[80%] object-contain" />
              )}
            </div>

            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#1f2937",
            color: "#fff",
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
          },
        }}
      >
        <SidebarComponent
          setIsSidebarVisible={setIsSidebarOpen}
          {...{ logoUrl, loading, handleLogout, isMobile }}
        />
      </Drawer>

      {/* Main Content */}
      <div
        style={{
          marginTop: isMobile ? "64px" : 0, // Adjust for AppBar height on mobile
          marginLeft: isMobile ? 0 : `${drawerWidth}px`, // Adjust for fixed sidebar
          padding: "16px",
          boxSizing: "border-box",
        }}
      >
        {children}
      </div>

      {/* Footer */}
      <Box
        sx={{
          marginLeft: isMobile ? 0 : `${drawerWidth}px`,
          padding: "10px",
          backgroundColor: "white",
          borderTop: "1px solid #E5E7EB",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <FooterComponent />
      </Box>
    </div>
  );
};

export default LayoutComponent;
