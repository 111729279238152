import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  Checkbox,
  Menu,
  IconButton,
  TableCell,
  Typography,
  TableFooter,
  Tooltip,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Fade,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextsmsIcon from "@mui/icons-material/Textsms";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import JsonData from "../client/data.json";
import ViewModal from "../client/viewModal";
import InfoIcon from "@mui/icons-material/Info";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/Inventory";
import TuneIcon from "@mui/icons-material/Tune";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MarkerImage from "../../assets/images/marker.png";
import "leaflet/dist/leaflet.css";
import Client from "../client/client";
import { useToken } from "../../context/TokenContext";
import dotnetApiClient from "../../config/dotnetApiClient";
import EmptyState from "../EmptyState/EmptyState";
import ChatDialog from "./ChatDialog";
import {
  formatDateOnly,
  formatDateTime,
  logErrorFunction,
  removeSpaces,
} from "../../helper/service";
import AddClientModal from "../client/AddClientModal";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Beenhere, CoPresent, ExpandMore, Note } from "@mui/icons-material";
import ManageCaseState from "./ManageCaseState";
import TruncatedText from "../TruncatedText/TruncatedText";
import CaseStateFilter from "./CaseStateFilter";
import LoadingOverlay from "../Loader/LoadingOvelay";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ClientType {
  id: number;
  name: string;
  email: string;
  phone: string;
  caseId: number;
  progressPercentage: number;
  createDtUtc: string;
  lastLoginDtUtc: string;
  unreadMessageCount?: number;
}

interface FilterableColumnTableProps {
  setShowAddClientModal: any;

  showAddClientModal: any;
}

export const FilterableColumnTable: React.FC<FilterableColumnTableProps> = ({
  setShowAddClientModal,
  showAddClientModal,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const tempDataJson: any = JSON.parse(localStorage.getItem("dataJson") || "{}");
  const [setDataJsonTemp] = useState<any>(tempDataJson);
  const [clients, setClients] = useState<Client[]>([]);
  const [uiUserData, setUiUserData] = useState<Client[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedClientDetails, setSelectedClientDetails] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [tabValue, setTabValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [markers] = useState<any[]>([{ markers: [], name: "" }]);
  const [clientToEdit, setClientToEdit] = useState<Client | null>(null);
  const { token, setToken } = useToken();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<number | null>(null);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<any>();
  const processedClients = useRef<Set<number>>(new Set());
  const [searchTerm, setSearchTerm] = useState("");

  const [isClientDeleting, setIsClientDeleting] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(JsonData.columns);
  const [actionMenuAnchors, setActionMenuAnchors] = useState<{ [key: number]: HTMLElement | null }>(
    {}
  );
  const menuRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([
    "id",
    "name",
    "progressPercentage",
    "createDtUtc",
    "notes",
    "state",
    "advocateName",
  ]);
  const lawyerData = JSON.parse(localStorage.getItem("lawyerData") || "{}");
  const advocates = lawyerData?.availableAdvocates?.map((a: any) => a?.name) || [];
  const caseStates = lawyerData?.availableCaseStates || [];
  const [caseStateFilter, setCaseStateFilter] = useState<any>({
    advocateName: [],
    state: ["Unassigned", ...caseStates.filter((state: any) => state !== "Filed")],
  });
  const sortableColumns = [
    "id",
    "name",
    "email",
    "progressPercentage",
    "advocateName",
    "state",
    "createDtUtc",
    "lastLoginDtUtc",
    "caseName",
    "phoneNumber",
  ];

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate("/login");
  };
  const fetchDataFromAPI = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }

    setIsLoadingClients(true);
    try {
      const response = await dotnetApiClient().get("/clients");
      if (response.data) {
        setUiUserData(response.data);
        setClients(response.data);
      }
    } finally {
      setIsLoadingClients(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  useEffect(() => {
    setPage(0);
    const clientsList = clients.filter(
      (c: any) =>
        (c.name + c.email).toLowerCase().includes(searchTerm.toLowerCase()) &&
        (caseStateFilter.advocateName.includes(c?.caseState?.advocateName || "Unassigned") ||
          !caseStateFilter.advocateName?.length) &&
        (caseStateFilter.state.includes(c?.caseState?.state || "Unassigned") ||
          !caseStateFilter.state?.length)
    );
    setUiUserData(clientsList);
  }, [searchTerm, caseStateFilter, clients]);
  const DynamicText = ({ text = "", maxLength = 20, ...props }) => {
    return (
      <Box
        sx={{
          wordWrap: "break-word",
          whiteSpace: text.length > maxLength ? "normal" : "nowrap",
        }}
      >
        <Typography variant="inherit" {...props}>
          {text}
        </Typography>
      </Box>
    );
  };
  const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>, clientId: number) => {
    event.stopPropagation();
    setActionMenuAnchors((prev) => {
      const allClosed = Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: null }), {});
      return {
        ...allClosed,
        [clientId]: prev[clientId] !== event.currentTarget ? event.currentTarget : null,
      };
    });
  };
  const handleCloseActionMenu = (clientId: number) => {
    setActionMenuAnchors((prev) => ({
      ...prev,
      [clientId]: null,
    }));
  };

  useEffect(() => {
    if (location?.state?.caseStateKeyValue)
      setCaseStateFilter((prev: any) => ({
        ...prev,
        state: [],
        [location?.state?.caseStateKey]: [location?.state?.caseStateKeyValue],
      }));
  }, [location]);

  // Close menu on outside click
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      Object.keys(actionMenuAnchors).forEach((key) => {
        const clientId = parseInt(key);
        if (actionMenuAnchors[clientId] && menuRefs.current[clientId]) {
          if (!menuRefs.current[clientId]?.contains(event.target as Node)) {
            handleCloseActionMenu(clientId);
          }
        }
      });
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [actionMenuAnchors]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonOptions = {
    sort: true,
  };
  const handleChangeHeaderMens = (event: any, checkedvalue: any) => {
    setSelectedFilters((prev) =>
      prev.includes(checkedvalue)
        ? prev.filter((value) => value !== checkedvalue)
        : [...prev, checkedvalue]
    );
  };
  useEffect(() => {
    const filteredMenu = JsonData.columns.filter(
      (menu: any) => selectedFilters.includes(menu.value) || menu.value === "action"
    );
    setSelectedMenu(filteredMenu);
  }, [selectedFilters]);
  useEffect(() => {
    tableContainerRef?.current?.scrollTo(0, 0);
  }, [page]);

  const generateColumn = (
    name: string,
    label: string,
    tooltiptitle: string,
    options = {},
    sortable: string
  ) => ({
    name,
    label,
    tooltiptitle,
    options: {
      customHeadRender: ({ index, ...column }: any) => {
        return (
          <TableCell key={index} sx={{ py: "8px" }} onClick={() => handleSort(name)}>
            <IconButton>
              <InfoIcon />
            </IconButton>
            <Typography sx={{ fontWeight: "700", color: "black" }}>{label}</Typography>
          </TableCell>
        );
      },
      ...options,
    },
    sortable,
  });

  let columns: any = selectedMenu
    .filter((items: any) => items.flag === true)
    .map((name: any) => {
      if (name?.flag) {
        return generateColumn(
          name?.value,
          name?.title,
          name?.tooltiptitle,
          {
            ...commonOptions,
          },
          (name.sortable = "asc")
        );
      }
      return null;
    })
    .filter((column: any) => column !== null);

  const handleViewCurrUser = (customer: any) => {
    setSelectedClientDetails(customer);
    setShowViewModal(!showViewModal);
  };

  const handleEditCurrUser = (user: any) => {
    const client: Client = {
      id: user.id,
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber ?? user.phone,
      progressPercentage: user.progressPercentage,
      caseType: "",
      caseId: user.id,
      jsonId: "",
      formName: "",
      formVersion: "",
      backgroundColor: user.color,
      createDtUtc: user.createDtUtc,
      lastLoginDtUtc: user.lastLoginDtUtc,
      uiFlowJsonID: user.uiFlowJsonID,
      formsForCaseTypes: user.formsForCaseTypes,
    };
    setClientToEdit(client);
    // setShowEditModal(true);
    setShowAddClientModal(true);
  };

  const handleSort = (field: string) => {
    const newSortOrder: any = sortOrder === "asc" ? "desc" : "asc";
    const sortedData: any = [...uiUserData];
    const numberIncluded = ["progressPercentage", "id"];
    sortedData.sort((a: any, b: any) => {
      const valueA =
        a?.[field] || a?.caseState?.[field] || a?.formsForCaseTypes?.[0]?.[field] || "";
      const valueB = b[field] || b?.caseState?.[field] || b?.formsForCaseTypes?.[0]?.[field] || "";
      if (newSortOrder === "asc") {
        if (numberIncluded?.includes(field)) {
          return a?.[field] - b?.[field];
        } else {
          return valueA?.localeCompare(valueB);
        }
      } else {
        if (numberIncluded?.includes(field)) {
          return b?.[field] - a?.[field];
        } else {
          return valueB?.localeCompare(valueA);
        }
      }
    });

    setSortOrder(newSortOrder);
    setUiUserData(sortedData);
  };

  const handleChange = (event: any, selectedData: any) => {
    let tempFlag: boolean = !selectedData?.flag;
    let tempJsonData = [...JsonData.columns];
    tempJsonData = tempJsonData.map((columnData: any) => {
      if (selectedData?.value === columnData?.value) {
        return {
          ...columnData,
          flag: tempFlag,
        };
      }
      return columnData;
    });

    setDataJsonTemp(tempJsonData);
    localStorage.setItem("dataJson", JSON.stringify(tempJsonData));
  };

  const TablePaginationActions = (props: any) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event: any) => {
      onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: any) => {
      onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: any) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: any) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const handleClickOpenDeleteDialog = (clientId: number) => {
    setClientToDelete(clientId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setClientToDelete(null);
  };

  const handleDeleteClient = async () => {
    if (clientToDelete === null) return;
    setIsClientDeleting(true);
    try {
      const axiosInstance = dotnetApiClient();
      const response = await axiosInstance.delete(`/clients/${clientToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 204 || response.status === 200) {
        setUiUserData((prevUiUserData) =>
          prevUiUserData.filter((client) => client.id !== clientToDelete)
        );
      } else {
        logErrorFunction("Failed to delete client");
      }
    } catch (error: any) {
      logErrorFunction("Error deleting client:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setOpenDeleteDialog(false);
      setClientToDelete(null);
      setIsClientDeleting(false);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEmulate = (client: ClientType) => {
    navigate("/iframe", {
      state: { role: "lawyer", token: token, user: client },
    });
  };

  const renderName = (customerName: string | undefined) => {
    if (!customerName) return "";
    const temp = customerName.split(" ");
    if (temp.length < 2) return customerName.charAt(0);
    const finalName = temp[0].charAt(0) + temp[1].charAt(0);
    return finalName;
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  var markerIcon = L.icon({
    iconUrl: MarkerImage,
    iconSize: [60, 60],
    iconAnchor: [60, 60],
    popupAnchor: [10, -56],
  });

  const handleChat = (customer: any) => {
    setShowChatModal(true);
    setCurrentClient(customer);
  };
  ////// Chat start ///
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({}); // Loading state for each user

  useEffect(() => {
    if (uiUserData.length === 0) return;

    const processClients = async () => {
      for (const clientData of uiUserData) {
        // Check if the client has already been processed
        if (!processedClients.current.has(clientData.id)) {
          try {
            // Set loading to true for this client
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: true,
            }));
            // Mark the client as processed immediately to prevent duplicate processing
            processedClients.current.add(clientData.id);
            // Wait for both API calls to complete and get the unread message count
            const unreadMessageCount = await callApisForClient(clientData);

            // Update the uiUserData with the unreadMessageCount for the processed client
            setUiUserData((prevState) =>
              prevState.map((user) =>
                user.id === clientData.id ? { ...user, unreadMessageCount } : user
              )
            );
            // Set loading to false for this client after processing
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: false,
            }));
          } catch (err) {
            console.error(err);
            // Set loading to false in case of an error
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: false,
            }));
          }
        }
      }
    };
  }, [uiUserData]);

  // Function to call both APIs using Promise.all and return the unreadMessageCount
  const callApisForClient = async (clientData: any) => {
    try {
      if (clientData?.id) {
        const requestData = { withUserId: clientData?.id };
        // Call both APIs in parallel
        const [chatResponse, messageInfoResponse] = await Promise.all([
          dotnetApiClient().post("/chat", requestData), // Chat API call
          dotnetApiClient().post("/chat/message/info", requestData), // Message Info API call
        ]);
        // Extract unreadMessageCount from the messageInfoResponse
        const unreadMessageCount = messageInfoResponse.data?.unreadMessageCount;
        return unreadMessageCount;
      }
    } catch (error: any) {
      logErrorFunction("Error fetching data: " + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
      throw error; // Rethrow the error to handle in useEffect
    }
  };

  const updateUnreadCount = (newUnreadCount: number) => {
    setUiUserData((prevState) =>
      prevState.map((user) =>
        user.id === currentClient?.id ? { ...user, unreadMessageCount: newUnreadCount } : user
      )
    );
  };
  //chat end //

  return (
    <>
      <Box>
        <Box className="dashboard filterableColumnTable">
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
            <Box sx={{ display: "flex", flex: 1, overflow: "auto" }} className="custom-scroll">
              {JsonData.columns
                .filter((items: any) => items.value !== "action")
                .map((option) => (
                  <Box
                    id={`${option?.value}_column_option_item`}
                    key={option.value}
                    onClick={(e) => handleChangeHeaderMens(e, option.value)}
                    sx={{
                      paddingRight: "16px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      pointerEvents: ["advocateName", "state"].includes(option?.value)
                        ? "none"
                        : "auto",
                    }}
                  >
                    <Checkbox
                      id={`${option?.value}_column_option_checkbox`}
                      checked={selectedFilters.includes(option.value)}
                      disabled={["advocateName", "state"].includes(option?.value)}
                    />
                    <ListItemText
                      id={`${option?.value}_column_option_text`}
                      primary={option.tooltiptitle}
                    />
                  </Box>
                ))}
            </Box>
            <TextField
              id="search_by_email_or_name"
              placeholder="Search client by name or email"
              variant="outlined"
              fullWidth
              size="small"
              style={{
                marginLeft: "auto",
                maxWidth: 300,
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <CaseStateFilter
                title="Advocate"
                name="advocateName"
                {...{ caseStateFilter, setCaseStateFilter }}
                options={advocates}
              />
              <CaseStateFilter
                title="State"
                name="state"
                {...{ caseStateFilter, setCaseStateFilter }}
                options={caseStates}
              />
            </Box>
          </Box>
          <Box className="inner_filterableColumnTable" sx={{ padding: 0, mt: 2 }}>
            {/* <CustomTabPanel value={tabValue} index={0}> */}
            <Box className="filterTableContainer">
              <TableContainer
                ref={tableContainerRef}
                className={uiUserData.length === 0 ? "" : "client_table_container"}
                style={{ border: "1px solid #ddd" }}
              >
                {isLoadingClients ? (
                  <LoadingOverlay
                    text="Loading..."
                    style={{
                      position: "static",
                      background: "#fff",
                      margin: "auto",
                      color: "#000",
                      padding: "100px 0",
                    }}
                  />
                ) : uiUserData.length === 0 ? (
                  <Box className="empty_state" sx={{ textAlign: "center", marginTop: 4 }}>
                    <EmptyState />
                  </Box>
                ) : (
                  <Table className="client_table client_table_dashboard">
                    <TableHead className="client_table_head">
                      <TableRow className="client_table_row">
                        {columns?.map((column: any, ind: any) => (
                          <TableCell
                            className="client_table_cell"
                            key={ind}
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#757575",
                              cursor: "pointer",
                              py: "8px",
                            }}
                            id={`${column?.name}_column-head`}
                            onClick={(e: any) => {
                              if (sortableColumns.includes(column?.name)) {
                                handleSort(column?.name);
                              }
                            }}
                          >
                            <Box className="client_table_icons" display="flex" alignItems="center">
                              {column?.name === "name" ? (
                                <span className="customTableHeader">
                                  <PeopleIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "progressPercentage" ? (
                                <span className="customTableHeader">
                                  <DonutLargeIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "email" ? (
                                <span className="customTableHeader">
                                  <EmailIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "phoneNumber" ? (
                                <span className="customTableHeader">
                                  <LocalPhoneIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "createDtUtc" ? (
                                <span className="customTableHeader">
                                  <CalendarMonthIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "lastLoginDtUtc" ? (
                                <span className="customTableHeader">
                                  <CalendarMonthIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "caseName" ? (
                                <span className="customTableHeader">
                                  <InventoryIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "state" ? (
                                <span className="customTableHeader">
                                  <Beenhere sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "notes" ? (
                                <span className="customTableHeader">
                                  <Note sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "advocateName" ? (
                                <span className="customTableHeader">
                                  <CoPresent sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : column?.name === "action" ? (
                                <span className="customTableHeader">
                                  <TuneIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              ) : null}
                              <Tooltip title={column?.tooltiptitle}>
                                <span className="client_table_icon_label">{column?.label}</span>
                              </Tooltip>
                              {sortableColumns.includes(column?.name) && (
                                <span className="swapvert_icon">
                                  <SwapVertIcon sx={{ mr: 1, fontSize: "26px" }} />
                                </span>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="client_table_body">
                      {(rowsPerPage > 0
                        ? uiUserData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : uiUserData
                      ).map((customer: any, index: any) => (
                        <TableRow
                          key={`${customer.id}_${customer.caseId}`}
                          id={`${customer.id}_${customer.caseId}`}
                        >
                          {columns.map((column: any) =>
                            column.name === "name" ? (
                              <TableCell
                                className="client_name"
                                key={column.name}
                                sx={{ py: "8px", maxWidth: 160 }}
                              >
                                <Tooltip title={customer[column.name]}>
                                  <div
                                    className="td_container"
                                    id={removeSpaces(
                                      `${customer[column.name]}_${customer?.caseId}_name`
                                    )}
                                  >
                                    <DynamicText
                                      text={customer[column.name]}
                                      id={removeSpaces(
                                        `${customer[column.name]}_${customer.caseId}`
                                      )}
                                    />
                                  </div>
                                </Tooltip>
                              </TableCell>
                            ) : column.name === "progressPercentage" ? (
                              <TableCell
                                className="client_progress"
                                key={column.progressPercentage}
                                sx={{ py: "8px" }}
                              >
                                <div
                                  id={removeSpaces(`${customer?.id}_${customer?.caseId}_progress`)}
                                >
                                  {customer?.progressPercentage || 0}%
                                </div>
                              </TableCell>
                            ) : column.name === "chat" ? (
                              <TableCell
                                className="client_chat"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <div
                                  className="message_view"
                                  id={removeSpaces(`${customer?.id}_message_view`)}
                                >
                                  <IconButton
                                    onClick={() => handleChat(customer)}
                                    className="unread_count_container"
                                  >
                                    {loading[customer.id] ? (
                                      <CircularProgress disableShrink size="20px" value={25} />
                                    ) : (
                                      <TextsmsIcon style={{ color: "#757575" }} />
                                    )}
                                    {customer?.unreadMessageCount > 0 && (
                                      <p className="unread_count_badge">
                                        {customer?.unreadMessageCount}
                                      </p>
                                    )}
                                  </IconButton>
                                </div>
                              </TableCell>
                            ) : column.name === "caseName" ? (
                              <TableCell
                                className="client_caseType"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <Tooltip title={customer[column.name]}>
                                  <div
                                    className="td_container"
                                    id={removeSpaces(
                                      `${customer?.id}_${customer?.caseId}_caseType`
                                    )}
                                  >
                                    {customer?.formsForCaseTypes?.[0]?.caseName}
                                  </div>
                                </Tooltip>
                              </TableCell>
                            ) : column.name === "createDtUtc" ? (
                              <TableCell
                                className="client_created_at"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <Tooltip title={customer[column.name]}>
                                  <div
                                    style={{ maxWidth: "80px" }}
                                    className="td_container"
                                    id={removeSpaces(
                                      `${customer?.id}_${customer?.caseId}_created_at`
                                    )}
                                  >
                                    {customer?.createDtUtc
                                      ? formatDateOnly(customer?.createDtUtc)
                                      : "Not signed in yet"}
                                  </div>
                                </Tooltip>
                              </TableCell>
                            ) : column.name === "lastLoginDtUtc" ? (
                              <TableCell
                                className="client_lastLogin"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <Tooltip title={customer[column.name]}>
                                  <div
                                    style={{ maxWidth: "80px" }}
                                    className="td_container"
                                    id={removeSpaces(
                                      `${customer?.id}_${customer?.caseId}_lastLogin`
                                    )}
                                  >
                                    {customer?.lastLoginDtUtc
                                      ? formatDateTime(customer?.lastLoginDtUtc)
                                      : "Not signed in yet"}
                                  </div>
                                </Tooltip>
                              </TableCell>
                            ) : column.name === "email" ? (
                              <TableCell
                                className="client_email"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <div
                                  className="message_view"
                                  id={removeSpaces(`${customer?.id}_${customer?.caseId}_email`)}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div
                                      id={removeSpaces(
                                        `${customer[column.name]}_${customer?.caseId}`
                                      )}
                                      className="td_container message_container"
                                    >
                                      <TruncatedText text={customer[column.name]} maxWidth={200} />
                                    </div>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            ) : column.name === "advocateName" ? (
                              <TableCell
                                className="client_lastLogin"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <ManageCaseState
                                  {...{
                                    options: advocates,
                                    name: "advocateName",
                                    client: customer,
                                    clients: uiUserData,
                                    setClients,
                                  }}
                                />
                              </TableCell>
                            ) : column.name === "notes" ? (
                              <TableCell
                                className="client_lastLogin"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <ManageCaseState
                                  {...{
                                    name: "notes",
                                    client: customer,
                                    clients: uiUserData,
                                    setClients,
                                  }}
                                />
                              </TableCell>
                            ) : column.name === "state" ? (
                              <TableCell
                                className="client_lastLogin"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <ManageCaseState
                                  {...{
                                    options: caseStates,
                                    name: "state",
                                    client: customer,
                                    clients: uiUserData,
                                    setClients,
                                  }}
                                />
                              </TableCell>
                            ) : column.name === "action" ? (
                              <TableCell
                                className="client_action"
                                key={column.name}
                                sx={{ cursor: "pointer", py: "8px" }}
                              >
                                <div className="action_buttons">
                                  <Button
                                    id={`client_emulate_btn-${customer.id}-${customer.caseId}`}
                                    variant="outlined"
                                    sx={{
                                      width: "98px",
                                      background: "#eff6ff",
                                    }}
                                    onClick={() => handleEmulate(customer)}
                                  >
                                    Emulate
                                  </Button>
                                  <div
                                    key={customer.id}
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <IconButton
                                      aria-label="more"
                                      id={`action-menu-btn-${customer.id}-${customer.caseId}`}
                                      aria-controls={
                                        actionMenuAnchors[customer.id + (index + 1)]
                                          ? `action-menu-${customer.id + (index + 1)}`
                                          : undefined
                                      }
                                      aria-expanded={Boolean(
                                        actionMenuAnchors[customer.id + (index + 1)]
                                      )}
                                      aria-haspopup="true"
                                      onClick={(e) =>
                                        handleActionMenuClick(e, customer.id + (index + 1))
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    {actionMenuAnchors[customer.id + (index + 1)] && (
                                      <div
                                        ref={(el) =>
                                          (menuRefs.current[customer.id + (index + 1)] = el)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "100%",
                                          right: 0,
                                          backgroundColor: "#fff",
                                          boxShadow:
                                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                                          borderRadius: "4px",
                                          minWidth: "120px",
                                          zIndex: 1000,
                                          marginTop: "8px",
                                        }}
                                      >
                                        <div style={{ padding: "8px 0" }}>
                                          {["View", "Edit", "Delete"].map((action) => (
                                            <div
                                              id={`${action}-${customer.id}-${customer.caseId}`}
                                              key={action}
                                              onClick={() => {
                                                switch (action) {
                                                  case "Edit":
                                                    handleEditCurrUser(customer);
                                                    break;
                                                  case "Delete":
                                                    handleClickOpenDeleteDialog(customer.id);
                                                    break;
                                                  case "View":
                                                    handleViewCurrUser(customer);
                                                    break;
                                                }
                                                handleCloseActionMenu(customer.id);
                                              }}
                                              style={{
                                                padding: "6px 16px",
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {action}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                            ) : (
                              <TableCell
                                className={column.name}
                                key={column.name}
                                sx={{ py: "8px" }}
                              >
                                <div className="td_container" style={{ whiteSpace: "nowrap" }}>
                                  {customer[column.name]}
                                </div>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter className="client_table_footer">
                      <TableRow className="client_table_footer_row">
                        <TablePagination
                          className="client_table_pagination"
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          colSpan={columns.length}
                          count={uiUserData?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          slotProps={{
                            select: {
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </TableContainer>
              {/* <FooterComponent />*/}
              {/* <ChatComponent /> */}
            </Box>
            {/* </CustomTabPanel> */}
            <CustomTabPanel value={tabValue} index={1}>
              <div className="container max-w-full h-[600px]">
                <MapContainer
                  center={markers[0]?.markers}
                  zoom={8}
                  scrollWheelZoom={true}
                  className="h-[70vh]"
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {markers?.map((data: any, index: number) => (
                    <Marker key={index} position={data?.markers} icon={markerIcon}>
                      <Popup>{`${data?.name} `}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
            </CustomTabPanel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {JsonData.columns.map((name: any) =>
                name?.title === "Action" ? null : (
                  <MenuItem
                    disabled={name?.title === "Name"}
                    key={name?.title}
                    value={name?.title}
                    onClick={(e: any) => handleChange(e, name)}
                  >
                    <Checkbox checked={name?.flag} />
                    <ListItemText primary={name?.title} />
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* Delete confirmation modal */}
      <Dialog
        className="modal_delete_confirmation"
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 2,
            width: "400px",
            maxWidth: "100%",
          },
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <DialogTitle
          className="modal_delete_title"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "error.main",
          }}
        >
          <WarningIcon sx={{ mr: 1 }} />
          Confirm Deletion
        </DialogTitle>
        <DialogContent className="modal_delete_content">
          <DialogContentText
            className="modal_delete_content_title"
            sx={{ color: "text.secondary", mb: 2 }}
          >
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="modal_delete_actions"
          sx={{ justifyContent: "space-between", paddingX: 3 }}
        >
          <Button
            className="modal_delete_cancel_btn"
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            color="inherit"
            sx={{
              color: "text.secondary",
              borderColor: "grey.500",
              "&:hover": {
                borderColor: "grey.700",
                backgroundColor: "grey.100",
              },
            }}
          >
            No
          </Button>
          <Button
            id="modal_delete_confirm_btn"
            onClick={handleDeleteClient}
            variant="contained"
            color="error"
            disabled={isClientDeleting}
            startIcon={isClientDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
            sx={{
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* View modal */}
      {showViewModal && (
        <ViewModal
          caseTypesOfClient={uiUserData?.filter(
            (u: any) => u?.email === selectedClientDetails?.email
          )}
          isViewPopup={showViewModal}
          setIsView={setShowViewModal}
          userId={selectedClientDetails?.id}
          selectedClientDetails={selectedClientDetails}
        />
      )}

      {/* Add client modal */}
      {showAddClientModal && (
        <AddClientModal
          client={clientToEdit}
          open={showAddClientModal}
          onClose={() => {
            setShowAddClientModal(false);
            setClientToEdit(null);
          }}
          fetchDataFromAPI={fetchDataFromAPI}
        />
      )}

      {/* Chat modal */}
      {showChatModal && (
        <ChatDialog
          showChatModal
          onClose={() => setShowChatModal(!showChatModal)}
          currentClient={currentClient}
          setUnreadCount={(newUnreadCount) => updateUnreadCount(newUnreadCount)}
        />
      )}
    </>
  );
};

export default FilterableColumnTable;
