import axios from "axios";
import { anonymousUser, generateGuid, getApiUrl } from "../helper/service";

const baseURL = window.API_URL;
console.log("baseURL ===>", baseURL);
let ongoingRequests: any = {};

const dotnetApiClient = (isFormData = false, allowAuth = true) => {
  // Generate or retrieve session ID
  let sessionID = sessionStorage.getItem("Usapath-Session-Id");

  if (!sessionID) {
    sessionID = generateGuid();
    sessionStorage.setItem("Usapath-Session-Id", sessionID);
  }

  // Setup headers
  const headers: any = {
    "Access-Control-Allow-Origin": "*",
    "UsaPath-Request-Id": generateGuid(),
    "Usapath-Session-Id": sessionID,
  };

  if (isFormData) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (allowAuth) {
    const token = localStorage.getItem("token");
    const accessToken = window.localStorage.getItem("accessToken");
    const csrfToken = window.localStorage.getItem("csrfToken");

    if (token) headers["Authorization"] = `Bearer ${token}`;
    if (accessToken) headers["Authorization"] = `Bearer ${accessToken}`;
    if (csrfToken) headers["csrfToken"] = csrfToken;
  }

  const usapathEmulateUserId = window.sessionStorage.getItem("Usapath-Emulate-UserId");
  if (usapathEmulateUserId) {
    headers["Usapath-Emulate-UserId"] = usapathEmulateUserId;
  }

  // Create axios instance
  let instance = axios.create({
    baseURL,
    withCredentials: false,
    headers,
  });

  // Function to add request to ongoingRequests map
  const addRequestToQueue = (requestId: any) => {
    ongoingRequests[requestId] = true;
  };

  // Function to remove request from ongoingRequests map
  const removeRequestFromQueue = (requestId: any) => {
    delete ongoingRequests[requestId];
  };

  // Request interceptor
  instance.interceptors.request.use(
    function (config: any) {
      if (config?.url === "/uiFormPayload") {
        const requestId = config?.headers["usapath-tag"];

        if (ongoingRequests[requestId]) {
          return Promise.reject("Request already in progress");
        }

        addRequestToQueue(requestId);
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    function (response: any) {
      const requestId = response?.config?.headers["usapath-etag"];

      if (response?.config?.url === "/uiFormPayload") {
        removeRequestFromQueue(requestId);
      }

      if (response?.headers["token"]) {
        localStorage.setItem("accessToken", response?.headers["token"]);
      }

      return response;
    },
    function (error: any) {
      if (error?.config?.url === "/uiFormPayload") {
        const requestId = error?.config?.headers["usapath-etag"];
        removeRequestFromQueue(requestId);
      }

      if (
        (error?.response?.status === 401 || error?.response?.status === 406) &&
        !anonymousUser()
      ) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      } else if (error?.response?.status === 500) {
        const errorScreen = document.getElementById("errorScreen");
        const errorTextSpan = errorScreen?.querySelector("span");

        if (errorTextSpan) {
          errorTextSpan.innerText = error?.config?.headers["UsaPath-Request-Id"];
        }

        errorScreen?.classList.add("shown");
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default dotnetApiClient;
