import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import { removeSpaces } from "../../helper/service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CaseStateFilter({
  title,
  options,
  name,
  caseStateFilter,
  setCaseStateFilter,
}: any) {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setCaseStateFilter((prev: any) => ({
      ...prev,
      [name]: value?.includes("clear") ? [] : typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <div>
      <FormControl size="small" sx={{ width: 150 }}>
        <InputLabel id={`${name}_label`}>{title}</InputLabel>
        <Select
          labelId={`${name}_label`}
          id={`${name}_filter`}
          multiple
          value={caseStateFilter?.[name] || []}
          onChange={handleChange}
          input={<OutlinedInput label={title} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          <MenuItem value="clear" id={`${name}_clear_item`}>
            <Typography color="primary" sx={{ ml: "auto" }} id={`${name}_clear_item-text`}>
              Clear All
            </Typography>
          </MenuItem>
          <MenuItem value={"Unassigned"} id={`${name}_Unassigned_item`}>
            <Checkbox
              checked={caseStateFilter?.[name]?.includes("Unassigned")}
              id={`${name}_Unassigned_item-checkbox`}
            />
            <em id={`${name}_Unassigned_item-text`}>Unassigned</em>
          </MenuItem>
          {options.map((o: any) => (
            <MenuItem key={o} value={o} id={`${removeSpaces(`${name}_${o}_item`)}`}>
              <Checkbox
                checked={caseStateFilter?.[name]?.includes(o)}
                id={`${removeSpaces(`${name}_${o}_item-checkbox`)}`}
              />
              <ListItemText primary={o} id={`${removeSpaces(`${name}_${o}_item-text`)}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
