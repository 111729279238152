import { Box, Typography } from "@mui/material";
import ClientsCount from "./ClientsCount";

const Dashboard: React.FC = () => {
  return (
    <>
      <Box className="lawyer_header_container">
        <Box className="add_client_btn_container">
          <Typography sx={{ fontSize: "26px", fontWeight: "700", minWidth: "60%" }}>
            Dashboard
          </Typography>
        </Box>
      </Box>
      <ClientsCount />
    </>
  );
};
export default Dashboard;
