import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TruncatedText from "../TruncatedText/TruncatedText";
import dotnetApiClient from "../../config/dotnetApiClient";
import { logErrorFunction, removeSpaces } from "../../helper/service";
import { useToken } from "../../context/TokenContext";
import { useNavigate } from "react-router-dom";
import EmptyState from "../EmptyState/EmptyState";
import LoadingOverlay from "../Loader/LoadingOvelay";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientsCount() {
  const [clients, setClients] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchDataFromAPI = async () => {
    if (!token) {
      logErrorFunction("Token is not available");
      return;
    }
    const handleLogout = () => {
      setToken(null);
      localStorage.clear();
      navigate("/login");
    };

    setLoading(true);
    try {
      const response = await dotnetApiClient().get("/clients");
      const data = response.data;
      let cleintTableData: any = [];
      Promise.all(
        data.map((item: any) => {
          const arr = { ...item };
          cleintTableData.push(arr);
        })
      );
      setClients(cleintTableData);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
        logErrorFunction("Error fetching clients:" + error);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  const groupBy = (key: any) => {
    const result = clients.reduce((acc: any, curr: any) => {
      const value = curr?.caseState?.[key];
      // Find if the advocateName already exists in the accumulator
      const found = acc?.find((item: any) => item?.[key] === value);
      if (found) {
        // If found, increment the count
        found.count += 1;
      } else {
        // If not found, add new entry with count 1
        acc.push({ [key]: value, count: 1 });
      }
      return acc;
    }, []);
    return result;
  };
  const advocateNameList = groupBy("advocateName");
  const stateList = groupBy("state");
  const handleCardClick = (key: any) => {
    navigate("/client", key);
  };
  const renderCard = (key: any, a: any, i: any) => (
    <Box
      sx={{ px: 1, cursor: "pointer" }}
      className="xl:w-1/6 md:w-1/4 w-1/2"
      key={i}
      id={removeSpaces(`${a[key] || "Unassigned"}_card`)}
      onClick={() =>
        handleCardClick({ state: { caseStateKey: key, caseStateKeyValue: a[key] || "Unassigned" } })
      }
    >
      <Box sx={{ bgcolor: a?.[key] ? "#1976d215" : "#ececec", p: 3, borderRadius: 2 }}>
        <Typography variant="subtitle1" sx={{ lineHeight: 1.3 }}>
          <TruncatedText
            id={removeSpaces(`${a[key] || "Unassigned"}_title`)}
            text={a?.[key] || "Unassigned"}
            maxWidth="100%"
          />
        </Typography>
        <Typography variant="h4" id={removeSpaces(`${a[key] || "Unassigned"}_count`)}>
          {a?.count}
        </Typography>
      </Box>
    </Box>
  );
  return loading ? (
    <LoadingOverlay
      text="Loading..."
      style={{
        position: "static",
        background: "#fff",
        margin: "auto",
        color: "#000",
        padding: "100px 0",
      }}
    />
  ) : clients?.length === 0 ? (
    <Box className="empty_state" sx={{ textAlign: "center", marginTop: 4 }}>
      <EmptyState />
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Box className="clientsCount-tabs">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab id="advocate_tab" label="By Advocate" {...a11yProps(0)} />
          <Tab id="state_tab" label="By State" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 2, mx: -1 }}>
          {advocateNameList?.map((a: any, i: any) => renderCard("advocateName", a, i))}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 2, mx: -1 }}>
          {stateList?.map((a: any, i: any) => renderCard("state", a, i))}
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
