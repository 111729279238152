import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allFormData: [],
  logoUrl: "",
};

export const formReducer = createSlice({
  name: "formflow",
  initialState,
  reducers: {
    setAllFormData: (state, action) => ({
      ...state,
      allFormData: action.payload,
    }),
    setLogoUrl: (state, action) => ({
      ...state,
      logoUrl: action.payload,
    }),
  },
});

export const { setAllFormData, setLogoUrl } = formReducer.actions;
export default formReducer.reducer;
